<template>
	<b-modal
		id="return-asset-issuance"
		title="Return Asset Issuance"
		ref="modal"
		ok-title="Proceed"
		@ok="handleOk"
		@show="onReset"
		:return-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<div class="confirmMessage">
			Are you sure you want to
			<b>return</b> this issuance <b>{{ selAssetIssuance.issuanceId }}</b
			>?
		</div>

		<br />
		<b-card-text>
			<b-form-group
				label="Issuance Id:"
				label-cols-sm="3"
				label-class="font-weight-bold pt-0"
				label-align-sm="right"
			>
				{{ selAssetIssuance.issuanceId }}
			</b-form-group>

			<b-form-group
				label="Status:"
				label-cols-sm="3"
				label-class="font-weight-bold pt-0"
				label-align-sm="right"
			>
				<AssetIssuanceRowStatus :item="selAssetIssuance" />
			</b-form-group>

			<b-form-group
				label="Issued To:"
				label-cols-sm="3"
				label-class="font-weight-bold pt-0"
				label-align-sm="right"
			>
				{{ selAssetIssuance.firstName + ' ' + selAssetIssuance.lastName }}
			</b-form-group>

			<b-form-group
				label="Asset:"
				label-cols-sm="3"
				label-class="font-weight-bold pt-0"
				label-align-sm="right"
			>
				{{
					selAssetIssuance.assetName + ' (' + selAssetIssuance.assetCode + ')'
				}}
			</b-form-group>

			<b-form-group
				label="Date Issued:"
				label-cols-sm="3"
				label-class="font-weight-bold pt-0"
				label-align-sm="right"
			>
				{{
					selAssetIssuance.dateIssued
						? getFormattedDateWithTime(selAssetIssuance.dateIssued)
						: '-'
				}}
			</b-form-group>

			<b-form-group
				label="Issuance Reason:"
				label-cols-sm="2"
				label-class="font-weight-bold pt-0"
				label-align-sm="right"
			>
				{{
					selAssetIssuance.description &&
					selAssetIssuance.description.length === 0
						? '-'
						: selAssetIssuance.description
				}}
			</b-form-group>
		</b-card-text>
	</b-modal>
</template>

<script>
// Component
import AssetIssuanceRowStatus from '@/views/asset/assetIssuance/AssetIssuanceRowStatus';

// Util
import { DateUtil } from '@/utils/dateutil';

// API
import assetIssuanceApi from '@/api/assetIssuanceApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'return-asset-issuance',
	components: {
		AssetIssuanceRowStatus,
		Loading,
	},
	data() {
		return {
			selAssetIssuance: {},

			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		issuanceId() {
			return this.selAssetIssuance.issuanceId;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelAssetIssuance', (assetIssuance) => {
			this.selAssetIssuance = assetIssuance;
		});
	},
	methods: {
		handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let issuanceId = this.selAssetIssuance.issuanceId;
			let returnedTo = `${this.loggedUser.firstName} ${this.loggedUser.lastName}`;
			let notes = ` Asset Issuance ${issuanceId} was returned to ${returnedTo};`;

			this.selAssetIssuance.notes += notes;
			this.selAssetIssuance.status = config.assetIssuanceStatus.RETURNED;
			this.selAssetIssuance['dateReturned'] = DateUtil.getCurrentTimestamp();
			this.selAssetIssuance['receivedBy'] = this.loggedUser.id;

			this.returnAssetIssuance(this.selAssetIssuance);
		},
		async returnAssetIssuance(assetIssuance) {
			try {
				let param = {
					currUserId: this.loggedUser.id,
					assetIssuances: [],
				};
				param.assetIssuances.push(assetIssuance);

				let { data } = await assetIssuanceApi.saveAssetIssuances(param);

				// hide loading indicator
				this.isLoading = false;

				if (data.isSuccess) {
					this.$toaster.success(
						`Asset Issuance "${this.issuanceId}" was returned successfully.`
					);
					EventBus.$emit('onCloseReturnAssetIssuance', data.assetIssuances[0]);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error returning Asset Issuance "${this.issuanceId}". Please try again.`
					);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error returning Asset Issuance "${this.issuanceId}". Please try again.`
				);
			}
		},
		onReset() {
			// do nothing
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelAssetIssuance');
	},
};
</script>

<style scoped>
</style>
