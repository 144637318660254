import { render, staticRenderFns } from "./ReturnAssetIssuance.vue?vue&type=template&id=60585652&scoped=true&"
import script from "./ReturnAssetIssuance.vue?vue&type=script&lang=js&"
export * from "./ReturnAssetIssuance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60585652",
  null
  
)

export default component.exports