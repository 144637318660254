<template>
	<div>
		<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
			@click.stop="row.toggleDetails" class="mr-1">
			<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
			<i class="fa fa-eye" v-else></i>
		</b-button>
		<b-button v-show="row.item.status === assetIssuanceStatus.ON_GOING && !isViewer" size="sm"
			v-b-modal.edit-asset-issuance v-b-tooltip.hover.top="'Edit Details'" variant="dark"
			@click.stop="updateSelAssetIssuance(row.item)" class="mr-1">
			<i class="fa fa-pencil"></i>
		</b-button>
		<b-button v-show="row.item.status === assetIssuanceStatus.ON_GOING && !isViewer" size="sm"
			v-b-modal.cancel-asset-issuance v-b-tooltip.hover.top="'Cancel Asset Issuance'" variant="danger"
			@click.stop="updateSelAssetIssuance(row.item)" class="mr-1 mt-1">
			<i class="fa fa-ban"></i>
		</b-button>
		<b-button v-show="row.item.status === assetIssuanceStatus.ON_GOING && !isViewer" size="sm"
			v-b-modal.return-asset-issuance v-b-tooltip.hover.top="'Return Asset Issuance'" variant="primary"
			@click.stop="updateSelAssetIssuance(row.item)" class="mr-1 mt-1">
			<i class="fa fa-reply"></i>
		</b-button>
	</div>
</template>

<script>
// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';

export default {
	name: 'asset-issuance-row-actions',
	props: {
		row: {
			type: Object,
			required: true,
		},
		isViewer: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			assetIssuanceStatus: config.assetIssuanceStatus,
		};
	},
	methods: {
		updateSelAssetIssuance(item) {
			this.selAssetIssuance = item;
			this.$store.commit('SET_CURR_ASSET_ISSUANCE', item);
			EventBus.$emit('onUpdateSelAssetIssuance', item);
		},
	},
};
</script>

<style></style>
