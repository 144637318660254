import axios from 'axios';
import _ from 'lodash';
import config from '@/config/env-constants';


function getProjectId() {
	if (config.currEnv === 'pjl') {
		return `ayun-${config.currEnv}`;
	} else {
		return `ares-${config.currEnv}-app`;
	}
}

export default {

    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    getAssetIssuances(filterBy, view, currUserId) {
        const url = `${this.baseUrl}/getAssetIssuances`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    saveAssetIssuances(param) {
        let url = `${this.baseUrl}/saveAssetIssuances`;
        return axios.post(url, {
            currUserId: param.currUserId,
            assetIssuances: JSON.stringify(param.assetIssuances)
        });
    },

    paginateAssetsForIssuance(page, limit, startAt, direction, filters, currUserId) {
        let url = `${this.baseUrl}/paginateAssetsForIssuance`;
        return axios.post(url, { ...filters, page, limit, startAt, direction, currUserId });
    },
}